import React, { Fragment } from "react";
import { PoseGroup } from "react-pose";

import Text from "components/atoms/Text";
import { SSRContext } from "components/utils/SSR";

import me from "cv/me.jpg";

import { Container, LeftSide, RightSide, Image, Skills, SkillDetail, SkillProgress, ImageWrapper } from "./styles";

const skills = [
  { name: "Ruby", ratio: "95%" },
  { name: "Ruby on Rails", ratio: "90%" },
  { name: "JavaScript", ratio: "90%" },
  { name: "NodeJS", ratio: "80%" },
  { name: "TypeScript", ratio: "85%" },
  { name: "React", ratio: "90%" },
  { name: "Angular", ratio: "95%" },
  { name: "CSS", ratio: "85%" },
  { name: "Crystal", ratio: "70%" },
];

const AboutView = () => (
  <Container>
    <LeftSide>
      <Text color="secondary" variant="h2">
        About me
      </Text>

      <Text color="light" variant="p">
        I enjoy turning complex problems into simple, beautiful and performant soutions. As a web developer, I have
        great obsession with detail, my complete devotion on making things and my mission-driven work ethic to change
        the world.
      </Text>

      <Text color="secondary" variant="h2">
        How I got here
      </Text>

      <Text color="light" variant="p">
        When I was starting University, I had decided that programming was not meant for me, but after spending a couple
        of months creating projects that interested me, I quickly discovered both an interest and a talent for problem
        solving and coding. Ever since then, I have devoted time and energy in honing my skills in both Ruby and
        JavaScript. In 2018 I started teaching a group of people the basics of JavaScript and programming best
        practices.
      </Text>
    </LeftSide>

    <RightSide>
      <ImageWrapper>
        <Image alt="" src={me} title="Me" />
      </ImageWrapper>

      <Text color="secondary" variant="h2">
        Skills
      </Text>

      <Skills>
        {skills.map(({ name, ratio }) => (
          <Fragment key={name}>
            <Text color="light">{name}</Text>

            <PoseGroup>
              <SkillDetail key={name}>
                <SSRContext.Consumer>
                  {ssr => <SkillProgress ratio={ratio} style={{ width: ssr ? ratio : undefined }} />}
                </SSRContext.Consumer>
              </SkillDetail>
            </PoseGroup>
          </Fragment>
        ))}
      </Skills>
    </RightSide>
  </Container>
);

export default AboutView;
