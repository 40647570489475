import React from "react";

import SEO from "components/utils/SEO";
import Container from "components/atoms/Container";
import AboutView from "components/views/About";

const AboutPage = () => (
  <Container top="sm" right="lg" left="lg">
    <SEO title="About" keywords={["About me", "Skills", "Programming"]} description="About me and my career" />

    <AboutView />
  </Container>
);

export default AboutPage;
