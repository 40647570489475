import posed from "react-pose";
import styled, { css } from "styled-components";

import media from "styles/media";
import variables from "styles/variables";
import { slideIn } from "utils/common";

const image = 250;
const spacing = 20;
const left = posed.section(slideIn("x", { enter: 0, exit: -100 }));
const right = posed.section(slideIn("x"));

const common = css`
  height: 100%;
  padding: 0 ${spacing}px ${spacing}px ${spacing}px;
  background-color: ${variables.colors.dark};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.md`flex-direction: row;`};
`;

export const LeftSide = styled(left)`
  ${common};
  flex: 2;
  max-width: 500px;
  ${media.md`margin-right: ${spacing}px;`};
`;

export const RightSide = styled(right)`
  ${common};
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  margin-top: ${spacing}px;
  border-radius: 50%;
  overflow: hidden;
  width: ${image}px;
  height: ${image}px;
  max-width: 100%;
  min-height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

export const Skills = styled.div`
  width: 100%;
`;

export const SkillDetail = styled.div`
  height: 5px;
  margin-top: 5px;
  margin-bottom: ${spacing}px;
  background-color: ${variables.colors.secondary};

  &:last-child {
    margin-bottom: 0;
  }
`;

const bar = posed.div({
  enter: {
    width: ({ ratio }) => ratio,
  },
  exit: {
    width: "0%",
  },
});

export const SkillProgress = styled(bar)`
  height: inherit;
  background-color: ${variables.colors.primary};
`;
